export const getDiscount = (price, cuttedPrice) => {
    if (cuttedPrice === 0) {
        return 0;
    }
    return (((price - cuttedPrice) / price) * 100).toFixed();
}

export const calculateTotalPrice = (cartItems) => {
    const totalPrice = cartItems.reduce((acc, item) => {
      const itemPrice = item.cuttedPrice > 0 ? item.cuttedPrice : item.price;
      return acc + itemPrice * item.quantity;   
    }, 0);
  
    return totalPrice;
}

export const getDeliveryDate = () => {
    const deliveryDate = new Date();
    deliveryDate.setDate(new Date().getDate() + 1)
    return deliveryDate.toUTCString().substring(0, 11);
}

export const formatDate = (dt) => {
    return new Date(dt).toUTCString().substring(0,16);
}

export const getRandomProducts = (prodsArray, n) => {
    return prodsArray.sort(() => 0.5 - Math.random()).slice(0, n)
}