import { Link } from 'react-router-dom';
import appliances from '../../assets/images/Categories/appliances.png';
import beauty from '../../assets/images/Categories/beauty.png';
import electronics from '../../assets/images/Categories/electronics.png';
import mobiles from '../../assets/images/Categories/phone.png';
import { categories } from '../../utils/constants';

const catNav = [
    {
        name: "Mobiles",
        icon: mobiles,
    },
    {
        name: "Accessories",
        icon: electronics,
    },
    {
        name: "Electronics",
        icon: appliances,
    },
    {
        name: "Storage",
        icon: beauty,
    },
]

const catNav2 = categories.map((item, i) => {
    return {
        name: item.name,
        icon: item.icon,
    }
})

const Categories = () => {
    return (
        <section className="hidden sm:block bg-white mt-10 mb-4 min-w-full px-12 py-1 shadow overflow-hidden">

            <div className="flex items-center justify-between mt-4">

                {catNav.map((item, i) => (
                    <Link to={`/products?category=${item.name}`} className="flex flex-col gap-1 items-center p-2 group" key={i}>
                        <div className="h-16 w-16">
                            <img draggable="false" className="h-full w-full object-contain" src={item.icon} alt={item.name} />
                        </div>
                        <span className="text-sm text-gray-800 font-medium group-hover:text-primary-blue">{item.name}</span>
                    </Link>
                ))}

            </div>
        </section>
    );
};

export default Categories;
