import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, getProductsCount, getSliderProducts } from '../../actions/productAction';
import Categories from '../Layouts/Categories';
import Loader from '../Layouts/Loader';
import MetaData from '../Layouts/MetaData';
import ProductSlider from './ProductSlider/ProductSlider';

const Home = () => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  let { error, loading, productsCount, fetchedProductsCount } = useSelector((state) => state.products);
  console.log(productsCount, fetchedProductsCount);
  // fetchedProductsCount = fetchedProductsCount ? fetchedProductsCount : 0;
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getProductsCount());
   if(productsCount > fetchedProductsCount) dispatch(getSliderProducts());
  }, [dispatch, error, enqueueSnackbar, productsCount]);

  return (
    <>
      <MetaData title="Buy Mobile Phones, Chargers, Laptops | Legitgadgets UG- Online Shopping Uganda. Best Offers!" />
      <Categories />
      <main className="flex flex-col gap-3 px-2 mt-10 sm:mt-2">
        {/* <Banner /> */}
        {/* <DealSlider title={"Discounts for You"} /> */}
        {loading ? <Loader /> : <ProductSlider title={"Suggested for You"} tagline={"Discounts Available"} category="Accessories" />}
        {/* <DealSlider title={"Top Brands, Best Price"} /> */}
        {loading ? <Loader /> : <ProductSlider title={"You May Also Like..."} tagline={"Discounts Available"} category="Mobiles" />}
        {/* <DealSlider title={"Top Offers On"} /> */}
        {loading ? <Loader /> : <ProductSlider title={"Don't Miss These!"} tagline={"Discounts Available"} category="Storage Devices" />}
        {loading ? <Loader /> : <ProductSlider title={"Don't Miss These!"} tagline={"Discounts Available"} category="Electronics" />}
      </main>
    </>
  );
};

export default Home;
