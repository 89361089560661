import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import ImageIcon from "@mui/icons-material/Image";
import { useDispatch } from "react-redux";
import { createBrand, getBrands } from "../../actions/brandAction";
import { useSelector } from "react-redux";

const filter = createFilterOptions();

export default function Brands({setBrand, brandValue}) {
  const [value, setValue] = React.useState(brandValue.name);
  const [open, toggleOpen] = React.useState(false);
  const [logo, setLogo] = React.useState("");
  const [logoPreview, setLogoPreview] = React.useState("");
  const dispatch = useDispatch();
  const { loading, brands } = useSelector((state) => state.brands);

  React.useEffect(() => {
    dispatch(getBrands());
    console.log(value);
    console.log(brandValue, 'brandValue')
  }, [dispatch, value, brandValue])

  const handleClose = () => {
    setDialogValue({
      name: "",
      logo: "",
    });
    toggleOpen(false);
  };

  console.log(brands, loading)

  const handleLogoChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setLogoPreview(reader.result);
        setLogo(reader.result);
        setDialogValue({
            ...dialogValue,
            logo: reader.result,
          })
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
    logo: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(dialogValue)
    // setValue({
    //   name: dialogValue.name,
    //   logo: dialogValue.logo,
    // });
    dispatch(createBrand(dialogValue))
    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            console.log(newValue, 'newValue')
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
                logo: "",
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue,
              logo: "",
            });
          } else {
            setValue(newValue);
            setBrand(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={brands}
        getOptionLabel={(option) => {
          // e.g. value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => <TextField {...params} label="Select Brand" />}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new film</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add New Brand
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label="name"
              type="text"
              variant="standard"
            />

            <div className="w-24 h-10 flex items-center justify-center border rounded-lg">
              {!logoPreview ? (
                <ImageIcon />
              ) : (
                <img
                  draggable="false"
                  src={logoPreview}
                  alt="Brand Logo"
                  className="w-full h-full object-contain"
                />
              )}
            </div>
            <label className="rounded bg-gray-400 text-center cursor-pointer text-white py-2 px-2.5 shadow hover:shadow-lg">
              <input
                type="file"
                name="logo"
                accept="image/*"
                onChange={handleLogoChange}
                className="hidden"
              />
              Choose Logo
            </label>
            {/* <TextField
              margin="dense"
              id="name"
              value={dialogValue.logo}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  logo: event.target.value,
                })
              }
              label="logo"
              type="number"
              variant="standard"
            /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

