// import axios from "axios";
import {
    ALL_BRANDS_FAIL,
    ALL_BRANDS_REQUEST,
    ALL_BRANDS_SUCCESS,
    BRAND_DETAILS_REQUEST,
    BRAND_DETAILS_SUCCESS,
    BRAND_DETAILS_FAIL,
    ADMIN_BRANDS_REQUEST,
    ADMIN_BRANDS_SUCCESS,
    ADMIN_BRANDS_FAIL,
    CLEAR_ERRORS,
    NEW_REVIEW_REQUEST,
    NEW_REVIEW_SUCCESS,
    NEW_REVIEW_FAIL,
    NEW_BRAND_REQUEST,
    NEW_BRAND_SUCCESS,
    NEW_BRAND_FAIL,
    UPDATE_BRAND_REQUEST,
    UPDATE_BRAND_SUCCESS,
    UPDATE_BRAND_FAIL,
    DELETE_BRAND_REQUEST,
    DELETE_BRAND_SUCCESS,
    DELETE_BRAND_FAIL,
    ALL_REVIEWS_REQUEST,
    ALL_REVIEWS_SUCCESS,
    ALL_REVIEWS_FAIL,
    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAIL,
    SLIDER_BRANDS_REQUEST,
    SLIDER_BRANDS_SUCCESS,
    SLIDER_BRANDS_FAIL,
    ADD_BRAND,
} from "../constants/brandConstants";
import axios from "../utils/axios";

// Get All Brands --- Filter/Search/Sort
export const getBrands =
    (keyword = "", category, price = [0, 200000], ratings = 0, currentPage = 1) => async (dispatch) => {
        try {
            dispatch({ type: ALL_BRANDS_REQUEST });

            let url = `${process.env.REACT_APP_API_URL}/api/v1/brands/all`;
            if (category) {
                url = `${process.env.REACT_APP_API_URL}/api/v1/brands/all`;
            }
            const { data } = await axios.get(url);

            dispatch({
                type: ALL_BRANDS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: ALL_BRANDS_FAIL,
                payload: error.response.data.message,
            });
        }
    };

// Get All Brands Of Same Category
export const getSimilarBrands = (category) => async (dispatch) => {
    try {
        dispatch({ type: ALL_BRANDS_REQUEST });

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/brands?category=${category}`);

        dispatch({
            type: ALL_BRANDS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ALL_BRANDS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get Brand Details
export const getBrandDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: BRAND_DETAILS_REQUEST });

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/brand/${id}`);

        dispatch({
            type: BRAND_DETAILS_SUCCESS,
            payload: data.brand,
        });
    } catch (error) {
        dispatch({
            type: BRAND_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// New/Update Review
export const newReview = (reviewData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_REVIEW_REQUEST });
        
        const { data } = await axios.put("/api/v1/review", reviewData);

        dispatch({
            type: NEW_REVIEW_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: NEW_REVIEW_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Get All Brands ---BRAND SLIDER
export const getSliderBrands = () => async (dispatch) => {
    try {
        dispatch({ type: SLIDER_BRANDS_REQUEST });

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/brands/all`);

        dispatch({
            type: SLIDER_BRANDS_SUCCESS,
            payload: data.brands,
        });
    } catch (error) {
        dispatch({
            type: SLIDER_BRANDS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get All Brands ---ADMIN
export const getAdminBrands = () => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_BRANDS_REQUEST });

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/brands`);

        dispatch({
            type: ADMIN_BRANDS_SUCCESS,
            payload: data.brands,
        });
    } catch (error) {
        dispatch({
            type: ADMIN_BRANDS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// New Brand ---ADMIN
export const createBrand = (brandData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_BRAND_REQUEST });
        const { data } = await axios.post("/api/v1/admin/brand/new", brandData);

        dispatch({
            type: NEW_BRAND_SUCCESS,
            payload: data,
        });

        dispatch({type: ADD_BRAND, payload: data.brand});

    } catch (error) {
        dispatch({
            type: NEW_BRAND_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Update Brand ---ADMIN
export const updateBrand = (id, brandData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_BRAND_REQUEST });
        
        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/admin/brand/${id}`, brandData);

        dispatch({
            type: UPDATE_BRAND_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_BRAND_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Delete Brand ---ADMIN
export const deleteBrand = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_BRAND_REQUEST });
        const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/admin/brand/${id}`);

        dispatch({
            type: DELETE_BRAND_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: DELETE_BRAND_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Get Brand Reviews ---ADMIN
export const getAllReviews = (id) => async (dispatch) => {
    try {
        dispatch({ type: ALL_REVIEWS_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/reviews?id=${id}`);

        dispatch({
            type: ALL_REVIEWS_SUCCESS,
            payload: data.reviews,
        });
    } catch (error) {
        dispatch({
            type: ALL_REVIEWS_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Delete Brand Review ---ADMIN
export const deleteReview = (reviewId, brandId) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_REVIEW_REQUEST });
        const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/admin/reviews?id=${reviewId}&brandId=${brandId}`);

        dispatch({
            type: DELETE_REVIEW_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: DELETE_REVIEW_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Clear All Errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}