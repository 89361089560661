// import notFound from '../assets/images/404-not-found.svg';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

const Error = ({ error, resetErrorBoundary }) => {
    return (
        <div className="mt-16 flex flex-col gap-4 items-center justify-center">
            <Typography variant="h3" gutterBottom>Something Went Wrong...</Typography>
            <Typography variant="h6" gutterBottom>{error.message}</Typography>
            <Button onClick={resetErrorBoundary} className="px-4 py-2 bg-primary-blue rounded-sm uppercase shadow hover:shadow-lg text-white">Try Again</Button>
        </div>
    );
};

export default Error;
