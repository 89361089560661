import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import MetaData from '../Layouts/MetaData';
import successfull from '../../assets/images/Transaction/success.png';
import failed from '../../assets/images/Transaction/failed.png';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Layouts/Loader';
import { clearErrors, newOrder } from '../../actions/orderAction';
import { emptyCart } from '../../actions/cartAction';


const ProcessingPayment = ({ success }) => {
    const navigate = useNavigate();
    const [time, setTime] = useState(3);
    const [searchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar();
    const transactionInfo = JSON.parse(searchParams.get('resp'));
    const { shippingInfo, cartItems } = useSelector((state) => state.cart);
    const totalPrice = cartItems.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    const { loading: orderLoading, order, error: orderError } = useSelector((state) => state.newOrder);

    const dispatch = useDispatch();
    
    console.log(transactionInfo);
    const orderData = {
        shippingInfo,
        orderItems: cartItems,
        totalPrice
    }
    useEffect(() => {
        if (time === 0) {
            if (transactionInfo.status == "success") {
                const { data: { txRef }} = transactionInfo
                orderData.paymentRef = txRef
                enqueueSnackbar("Transaction Successful", { variant: "success" });
                enqueueSnackbar("Order Placed", { variant: "success" });
                dispatch(newOrder(orderData));
            } else {
                navigate("/cart")
                enqueueSnackbar("Processing Payment Failed!", { variant: "error" });
            }
            return;
        };
        const intervalId = setInterval(() => {
            setTime(time - 1);
        }, 1000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [time]);

    useEffect(() => {
        if (orderLoading === false) {
            if (order) {
                enqueueSnackbar("Order Placed", { variant: "success" });
                dispatch(emptyCart());
                navigate("/orders/success");
            } else {
                navigate("/orders");
            }

            if (orderError) {
                navigate({
                    pathname: "/orders/failed",
                    search: `?${createSearchParams({
                        orderError
                    })}`
                })
                enqueueSnackbar(orderError, { variant: "error" });
                dispatch(clearErrors());
            }
        }
        // eslint-disable-next-line
    }, [orderLoading, orderError])

    return (
        <>
            <MetaData title={`Verifying Transaction`} />

            <Loader/>
        </>
    );
};

export default ProcessingPayment;